import React from "react";
import Navbar from "../sections/Navbar/Navbar";
import InternalCoachingHero from "../sections/InternalCoachingHero.";
import CaseStudies from "../sections/CaseStudies";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";
// @ts-ignore
import Seo from "../utils/seo";
import ExecutiveCoachingHero from "../sections/ExecutiveCoachingHero";
import Executives from "../sections/Executives";
import ExecutiveCoaches from "../sections/ExecutiveCoaches";
import What from "../sections/What";
// @ts-ignore
import Layout from "../layouts";
import Leaders from "../sections/Leaders";
const ExecutiveCoaching = () => {
  return (
    <>
      <Seo title="Executive Coaching for Transformative Leadership Growth" description="Experience expert coaching to enhance leadership, refine strategy, and inspire high performance. Achieve new heights with Coachello."
        // image={seoImg}        
      />

      <Layout>
      <ExecutiveCoachingHero />
      <Executives />
      <ExecutiveCoaches />
      <What />
      <Leaders />
      <Faq />
       </Layout>
    </>
  );
};

export default ExecutiveCoaching;
